import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';

function IntroPage(props) {
  let fontsToLoad = [
    'Work Sans', 'Lobster',
    // 'Dancing Script',
    // 'Raleway',
    // 'Poppins',
    // 'Playfair Display',
    // 'Bebas Neue',
    // 'Bangers',
    // 'Comfortaa',
    // 'Abril Fatface',
    // 'Righteous',
    // 'Fredoka One',
    // 'Pacifico',
    // 'Courgette'
  ]

  console.log(props.selectedFont)
  fontsToLoad.push(props.selectedFont)

  if (typeof window !== `undefined`) {
    WebFont.load({
      google: {
        families: fontsToLoad.map(font => font + ':lighter,normal,bold,bolder')
      }
    });
  }

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="row" justify="center" alignItems="center" style={{
          padding: '0px 0px 0px',
          backgroundColor: ''
        }}>
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            padding: '0px 0px 50px'
          }}>
          <Grid item="item">
            <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                // height: '25vh',
                backgroundColor: '',
                width: '300px'
              }}>
              <Typography style={{
                  fontFamily: 'Work Sans',
                  fontWeight: '200',
                  fontSize: '18px',
                  color: 'grey',
                  lineHeight: '1.1',
                  textAlign: 'center',
                  paddingBottom: '10px'
                  // textAlign: 'center'
                }}>My Brand</Typography>
              <Typography style={{
                  fontFamily: props.selectedFont,
                  fontWeight: 'bold',
                  fontSize: '30px',
                  lineHeight: '1.1',
                  textAlign: 'center'
                }}>{props.selectedBrandName}</Typography>
            </Grid>
          </Grid>
          <Grid item="item">
            <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                // height: '25vh',
                backgroundColor: '',
                padding: '30px',
                width: '300px'
              }}>
              <Typography style={{
                  fontFamily: 'Work Sans',
                  fontWeight: '200',
                  fontSize: '18px',
                  color: 'grey',
                  lineHeight: '1.1',
                  textAlign: 'center',
                  paddingBottom: '10px'
                  // textAlign: 'center'
                }}>Main Font</Typography>
              <Typography style={{
                  fontFamily: props.selectedFont,
                  fontWeight: 'bold',
                  fontSize: '30px',
                  lineHeight: '1.1',
                  textAlign: 'center'
                }}>{props.selectedFont}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container="container" direction="column" justify="flex-start" alignItems="center" style={{
            // height: '50vh',
            backgroundColor: ''
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontSize: '55px',
              fontWeight: 'bolder',
              textAlign: 'center',
              lineHeight: 1.1,
              // height: '40px',
              paddingBottom: '20px'
            }}>Okay, time to pick some colours.</Typography>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: 'normal',
              fontSize: '30px',
              paddingBottom: '30px',
              textAlign: 'center',
              lineHeight: 1.2
            }}>Would you like to start with a palette or from scratch?</Typography>
          <Grid container="container" direction="row" justify="center" alignItems="center">
            <Button onClick={event => props.setPage('SelectPalettePage')} variant='contained' color='secondary' size='large' style={{
                margin: '0px 10px'
              }}>Start with a Palette</Button>
            <Button onClick={event => props.setPage('EditPalettePage')} variant='outlined' color='secondary' size='large' style={{
                margin: '0px 10px'
              }}>From Scratch</Button>
          </Grid>
          <Button onClick={event => props.setPage('IntroFontPage')} size='large' style={{
              margin: '80px',
              color: 'grey'
            }}>Back to Fonts</Button>
        </Grid>
      </Grid>
    </Grid>
  </Grow>)
}

export default IntroPage

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
