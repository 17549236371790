import React, {useState} from "react"
import TextInput from "../WidgetComponents/TextInput"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

function EnterNamePage(props) {
  const [selection, setSelection] = useState(null)

  function selectBrandName(brandName) {
    setSelection(brandName)
    props.setSelectedBrandName(brandName)
  }

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
          backgroundColor: '',
          margin: 'auto'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontSize: '50px',
            fontWeight: 'bolder',
            textAlign: 'center',
            lineHeight: 1.1,
            // height: '40px',
            // padding: '30px 50px 50px 50px'
          }}>{
            selection
              ? selection
              : "What's Your Brand Called?"
          }</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '25px',
            padding: '15px 20px 30px'
          }}>Enter a name to get started.</Typography>
        <TextInput setSelection={selectBrandName} setPage={page => props.setPage(page)} selectionMade={selection !== null && selection !== ''}/>
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            padding: '30px'
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: 'normal',
              fontSize: '20px',
              color: 'grey'
            }}>Don't have a brand?</Typography>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: 'normal',
              fontSize: '20px',
              color: 'grey'
            }}>Try it with your own name!</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grow>)
}

export default EnterNamePage

// <NavButtons backPage='SearchFontPage' nextPage='SelectFontTypePage' setPage={page => props.setPage(page)} selectionMade={selection !== null && selection !== ''}/>
