import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ChromePicker} from 'react-color'
// import firebaseApp from '@firebase/app';
import update from 'immutability-helper';
import {firebase, auth, firestore} from '../../utils/firebase';
import {v4 as uuidv4} from 'uuid';
import {navigate} from 'gatsby';

// const firebase = getFirebase();

function EditPalettePage(props) {
  const [activeUser, setActiveUser] = useState(undefined);
  const [activePalette, setActivePalette] = useState(props.selectedPalette)
  const [colorState, setColorState] = useState(props.selectedPalette[0])
  const [activeColorIndex, setActiveColorIndex] = useState(0)
  const [open, setOpen] = useState(false);
  // console.log(props.selectedPalette)

  useEffect(() => {
    // if (!firebase)
    //   return;
    if (activeUser)
      return;
    console.log('Calling Firebase')
    auth.onAuthStateChanged((user) => {
      if (user) {
        // alert('User is signed in')
        // setIsSignedIn(!!user);
        setActiveUser(user);
      } else {
        // setIsSignedIn(undefined)
        // setActiveUser(undefined);
      }
    });
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(color) {
    setColorState(color.hex);
    setActivePalette(update(activePalette, {
      [activeColorIndex]: {
        $set: color.hex
      }
    }));
  };

  function updateActiveColor(index) {
    setActiveColorIndex(index)
    setColorState(activePalette[index])
  }

  function removeColor() {
    let newPalette = update(activePalette, {
      $splice: [
        [activeColorIndex, 1]
      ]
    })
    setActivePalette(newPalette)
    setActiveColorIndex(0)
    setColorState(newPalette[0])
  }

  function addColor() {
    let newPalette = update(activePalette, {$push: ['#EDEDED']})
    setActivePalette(newPalette)
  }

  // function nextPage() {
  //   props.setSelectedPalette(activePalette)
  //   props.setPage('CompletePage')
  // }

  function prevPage() {
    setActivePalette(['#EDEDED'])
    props.setSelectedPalette(['#EDEDED'])
    props.setPage('IntroColorPage')
  }

  function finalize() {
    const db = firestore

    var userRef = db
      .collection("users")
      .doc(activeUser.uid)

    userRef
      .get()
      .then(function(doc) {
        if (doc.exists) {

          let num_brands = doc
            .data()['brands']
            .length

          if (num_brands > 2) {
            navigate('/my/dashboard')
          } else {
            let new_num_brands = num_brands + 1

            let newBrandID = uuidv4()

            db
              .collection("brands")
              .doc(newBrandID)
              .set({creator: activeUser.uid, name: props.selectedBrandName, font: props.selectedFont, palette: activePalette})

            // console.log(userRef.data())

            userRef.update({
              brands: firebase
                .firestore
                .FieldValue
                .arrayUnion({id: newBrandID, name: props.selectedBrandName, font: props.selectedFont, palette: activePalette})
            });

            console.log("Added Brand")

            window
              .analytics
              .track("Created Brand", {
                id: newBrandID,
                user_ID: activeUser.id,
                name: props.selectedBrandName,
                font: props.selectedFont,
                palette: activePalette
              });
            window
              .analytics
              .identify(activeUser.uid, {
                // name: activeUser.displayName,
                // email: activeUser.email
                num_brands: new_num_brands
              });

            console.log("Segment Fired")

            navigate('/my/celebrate', {
              state: {
                brandCreated: true,
                brandURL: '/brand/?' + newBrandID
              }
            })
          }
        }
      })
  }

  let finalDialog = (<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">Ready to Generate?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Careful, there's no going back once you finalize your style guide. Make sure you're totally happy with your brand before moving forward.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} style={{
          color: 'grey'
        }}>
        Not Yet
      </Button>
      <Button variant='contained' onClick={finalize} color="secondary">
        Let's Do This
      </Button>
    </DialogActions>
  </Dialog>)

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
          backgroundColor: '',
          margin: 'auto'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: '200',
            fontSize: '20px'
          }}>My Brand</Typography>
        <Typography style={{
            fontFamily: props.selectedFont,
            fontWeight: 'bold',
            fontSize: '60px',
            padding: '10px',
            paddingTop: '40px'
          }}>{props.selectedBrandName}</Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            // width: '350px',
            height: '100px',
            // backgroundColor: 'red',
            marginBottom: '15px'
          }}>{
            activePalette.map((paletteColor, index) => <div onClick={event => updateActiveColor(index)} onKeyDown={event => updateActiveColor(index)} role='button' aria-label="Select colour" tabIndex={index} style={{
                backgroundColor: paletteColor,
                cursor: 'pointer',
                width: index === activeColorIndex
                  ? '80px'
                  : '50px',
                height: index === activeColorIndex
                  ? '80px'
                  : '50px',
                borderRadius: '100px',
                margin: index === activeColorIndex
                  ? '6px 10px'
                  : '6px',
                border: '0.5px solid #f7f7f7',
                transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
                // '&:hover': {
                //   flexGrow: 1.5
                // },
                outline: 'none'
              }}></div>)
          }
        </Grid>
        <ChromePicker color={colorState} onChangeComplete={handleChange} disableAlpha={true}/>
        <Grid container="container" direction="row" justify="center" alignItems="center">
          {
            (activePalette.length > 1) && <Button onClick={event => removeColor()} size='small' style={{
                  color: 'grey',
                  margin: '15px 5px'
                }}>Drop Colour</Button>
          }
          {
            (activePalette.length < 5) && <Button onClick={event => addColor()} size='small' style={{
                  color: 'grey',
                  margin: '15px 5px'
                }}>Add a Colour</Button>
          }
        </Grid>
        <Grid style={{
            margin: '20px'
          }}>
          <Button onClick={event => prevPage()} size='large' style={{
              margin: '10px',
              color: 'grey'
            }}>Back</Button>
          <Button onClick={handleClickOpen} variant="contained" color="secondary" size='large' style={{
              margin: '10px'
            }}>Finish</Button>
        </Grid>
      </Grid>
      {finalDialog}
    </Grid>
  </Grow>)
}

export default EditPalettePage
