import React, {useState, useEffect} from "react"
import NavButtons from "../WidgetComponents/NavButtons"
import FontList from "../WidgetComponents/FontList"
import Loader from '../WidgetComponents/Loader'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WebFont from 'webfontloader';

function SelectFontPage(props) {
  const [loaded, setLoaded] = useState(false)
  const [font, setFont] = useState(null)
  const [googleFonts, setGoogleFonts] = useState(null)
  const [fontsToLoad, setFontsToLoad] = useState(null)
  const [fontSlice, setFontSlice] = useState([0, 10])

  useEffect(() => {
    if (!loaded) {
      fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyADD6xPioonVhG9x-tNE0u7-2RvuV3-Jws')
        .then(function(response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response
            .json()
            .then(function(data) {
              let allGoogleFonts = data.items
              let googleFonts = allGoogleFonts
                .filter(font => font.category === props.selectedFontType)
                .map(font => font.family)
                .slice(0, 50)
              setGoogleFonts(googleFonts)
              let slicedFonts = googleFonts.slice(fontSlice[0], fontSlice[1])
              setFontsToLoad(googleFonts.slice(0, 10))
              // console.log(googleFonts.slice(0, 100))
              WebFont.load({
                google: {
                  families: googleFonts.slice(0, 50).map(font => font + ':lighter,normal,bold,bolder')
                },
                active: function() {
                  console.log(slicedFonts)
                  setLoaded(true)
                }
              });
            });
        })
        .catch(function(err) {
          console.log('Fetch Error :-S', err);
        });
    }
  }, [loaded, props.selectedFontType, fontSlice]);

  function nextPage() {
    let newFontSlice = [
      fontSlice[1], fontSlice[1] + 10
    ]
    // console.log(newFontSlice)
    setFontSlice(newFontSlice)
    let fontsToLoad = googleFonts.slice(newFontSlice[0], newFontSlice[1])
    setFontsToLoad(fontsToLoad)
    // WebFont.load({
    //   google: {
    //     families: fontsToLoad
    //   },
    //   active: function() {
    //     // setLoaded(true)
    //   }
    // });
  }

  function prevPage() {
    let newFontSlice = [
      fontSlice[0] - 10,
      fontSlice[0]
    ]
    // console.log(newFontSlice)
    setFontSlice(newFontSlice)
    let fontsToLoad = googleFonts.slice(newFontSlice[0], newFontSlice[1])
    setFontsToLoad(fontsToLoad)
    // WebFont.load({
    //   google: {
    //     families: fontsToLoad
    //   },
    //   active: function() {
    //     // setLoaded(true)
    //   }
    // });
  }

  function selectFont(font) {
    setFont(font)
    props.setSelectedFont(font)
  }

  let loadingMessage = "Grabbing Fonts"

  // console.log(fontSlice)

  return (
    loaded
    ? <Grow in="in">
      <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
          backgroundColor: '',
          height: '100vh',
          width: '100vw',
          padding: '0px 5vw'
        }}>
        <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
            backgroundColor: '',
            margin: 'auto'
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: 'lighter',
              fontSize: '20px'
            }}>Pick Your Main Font</Typography>
          <Typography style={{
              fontFamily: font === null
                ? 'Work Sans'
                : font,
              fontSize: '60px',
              fontWeight: 'bold',
              height: '80px',
              padding: '30px'
            }}>{props.selectedBrandName}</Typography>
          <Grid container="container" direction="row" justify="center" alignItems="center">
            <div style={{
                width: '50px'
              }}>
              {
                fontSlice[0] !== 0 && <IconButton onClick={prevPage}>
                    <ChevronLeftIcon/>
                  </IconButton>
              }</div>
            <Grid container="container" direction="row" justify="center" alignItems="center" style={{
                width: '70vw'
              }}>
              <FontList fonts={fontsToLoad} setFont={newFont => selectFont(newFont)} activeFont={font}/>
            </Grid>
            <div style={{
                width: '50px'
              }}>
              {
                fontSlice[0] !== 40 && <IconButton onClick={nextPage}>
                    <ChevronRightIcon/>
                  </IconButton>
              }</div>
          </Grid>
          <NavButtons backPage='SelectFontTypePage' nextPage='IntroColorPage' setPage={page => props.setPage(page)} selectionMade={font !== null}/>
        </Grid>
      </Grid>
    </Grow>
    : <Loader message={loadingMessage}/>)
}

export default SelectFontPage
