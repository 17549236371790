import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
// import Fade from '@material-ui/core/Fade';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import Button from '@material-ui/core/Button';

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder']
    }
  });
}

function SmallPage({
  data
}, props) {
  // const [paletteIndex, setPaletteIndex] = useState(2)
  // const [fontIndex, setFontIndex] = useState(1)
  // const [paletteAnimatedIn, setPaletteAnimatedIn] = useState(true)

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="column" justify="flex-start" alignItems="center" style={{
          // height: '60vh',
          backgroundColor: '',
          padding: '15px'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontSize: '40px',
            fontWeight: 'bolder',
            textAlign: 'center',
            lineHeight: 1,
            paddingBottom: '10px'
            // height: '40px',
            // padding: '30px 50px 50px 50px'
          }}>Creator mode is coming to mobile soon.</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '22px',
            paddingBottom: '30px',
            textAlign: 'center',
            lineHeight: 1.1
          }}>We're working on it. Try QuickStyle on your desktop to make a new brand.</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'light',
            fontSize: '18px',
            paddingBottom: '5px',
            textAlign: 'center',
            color: 'grey',
            lineHeight: 1.1
          }}>Already on a desktop?</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'light',
            fontSize: '18px',
            paddingBottom: '40px',
            textAlign: 'center',
            color: 'grey',
            lineHeight: 1.1
          }}>Try making your browser wider.</Typography>
        <Button size='large' color="secondary" variant='outlined' onClick={() => navigate('/my/dashboard')}>Back to Dashboard</Button>
      </Grid>
    </Grid>
  </Grow>)
}

export default SmallPage

// export const query = graphql `
//   query {
//     welcomeImage: file(
//       relativePath: { regex: "/welcome/" }
//     ) {
//       childImageSharp {
//         fluid(
//           maxWidth: 800
//           quality: 80
//         ) {
//           ...GatsbyImageSharpFluid_noBase64
//         }
//       }
//     }
//   }
// `

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.

// <NavButtons backPage='SearchFontPage' nextPage='SelectFontTypePage' setPage={page => props.setPage(page)} selectionMade={selection !== null && selection !== ''}/>
