import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function TextInput(props) {

  const handleChange = (event) => {
    props.setSelection(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    props.setPage('IntroFontPage')
  }

  return (<form onSubmit={handleSubmit}>
    <Grid container="container" direction='row' justify="center" alignItems="center">
      <TextField onChange={handleChange} id="outlined-search" label="My Brand Name" placeholder={''} type="search" variant="outlined" inputProps={{
          maxLength: 22
        }}/>
      <Button size="large" variant='contained' color="secondary" label="Submit" type="submit" disabled={!props.selectionMade} style={{
          margin: '20px'
        }}>
        Let's Go
      </Button>
    </Grid>
  </form>)
}
