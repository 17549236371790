import React, {useState, useEffect} from "react"
import Layout from "../../components/layout"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
// import Test from "../../components/PageComponents/TestPage.js"
import SmallPage from "../../components/PageComponents/SmallPage.js"
import CloseButton from "../../components/WidgetComponents/CloseButton"
// import NewIntroPage from "../../components/PageComponents/NewIntroPage.js"
import EnterNamePage from "../../components/PageComponents/EnterNamePage.js"
import IntroFontPage from "../../components/PageComponents/IntroFontPage.js"
import SelectFontTypePage from "../../components/PageComponents/SelectFontTypePage.js"
import SelectFontPage from "../../components/PageComponents/SelectFontPage.js"
import SearchFontPage from "../../components/PageComponents/SearchFontPage.js"
import IntroColorPage from "../../components/PageComponents/IntroColorPage.js"
import SelectPalettePage from "../../components/PageComponents/SelectPalettePage.js"
import EditPalettePage from "../../components/PageComponents/EditPalettePage.js"
import CompletePage from "../../components/PageComponents/CompletePage.js"
import MobileCompletePage from "../../components/PageComponents/MobileCompletePage.js"
import SEO from "../../components/seo"
import WebFont from 'webfontloader'
import {auth, firestore} from '../../utils/firebase';
import {navigate} from 'gatsby';
import Loader from '../../components/WidgetComponents/Loader'

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function IndexPage() {
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState(false)
  const [page, setPage] = useState('EnterNamePage')
  const [selectedBrandName, setSelectedBrandName] = useState(null)
  const [selectedFontType, setSelectedFontType] = useState(null)
  const [selectedFont, setSelectedFont] = useState(null)
  const [selectedPalette, setSelectedPalette] = useState(['#EDEDED'])
  const [finishedGenerating, setFinishedGenerating] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const db = firestore
        let docRef = db
          .collection("users")
          .doc(user.uid);
        docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {

              // var userRef = db
              //   .collection("users")
              //   .doc(activeUser.uid)

              console.log(doc.data())
              if (doc.data()['brands'].length > 1) {
                navigate('/my/dashboard')
              } else {
                setIsLoaded(true)
              }
            } else {
              // doc.data() will be undefined in this case
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });

        // if (userBrands.length < 2) {
        //   window
        //     .analytics
        //     .track("Clicked New Brand Button", {existingBrands: userBrands.length});
        //   navigate('/my/create')
        // } else {
        //   alert("Oops, you can only have 2 brands in QuickStyle... for now.")
        // }

      }
    })
  }, [isLoaded]);

  let isSmDown = (useMediaQuery(theme.breakpoints.down('sm')))
  let isXsDown = (useMediaQuery(theme.breakpoints.down('xs')))

  if (!isLoaded) {
    return <Loader/>
  }

  let renderedPage

  if (isSmDown) {
    if (page === 'CompletePage' && isXsDown) {
      renderedPage = <MobileCompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'CompletePage' && !isXsDown) {
      renderedPage = <CompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    } else {
      renderedPage = <SmallPage selectedPalette={["#e8ddcb", "#cdb380", "#036564", "#033649", "#031634"]} selectedBrandName='QuickStyle' selectedFont='Lobster' setPage={page => setPage(page)}/>
    }
  } else {
    if (page === 'EnterNamePage') {
      renderedPage = <EnterNamePage setSelectedBrandName={setSelectedBrandName} setPage={page => setPage(page)}/>
    } else if (page === 'IntroFontPage') {
      renderedPage = <IntroFontPage selectedBrandName={selectedBrandName} setPage={page => setPage(page)}/>
    } else if (page === 'SelectFontTypePage') {
      renderedPage = <SelectFontTypePage selectedBrandName={selectedBrandName} setSelectedFontType={setSelectedFontType} setPage={page => setPage(page)}/>
    } else if (page === 'SelectFontPage') {
      renderedPage = <SelectFontPage selectedBrandName={selectedBrandName} selectedFontType={selectedFontType} setSelectedFont={setSelectedFont} setPage={page => setPage(page)}/>
    } else if (page === 'SearchFontPage') {
      renderedPage = <SearchFontPage setSelectedFont={setSelectedFont} setPage={page => setPage(page)}/>
    } else if (page === 'IntroColorPage') {
      renderedPage = <IntroColorPage selectedBrandName={selectedBrandName} selectedFont={selectedFont} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'SelectPalettePage') {
      renderedPage = <SelectPalettePage selectedBrandName={selectedBrandName} selectedFont={selectedFont} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'EditPalettePage') {
      renderedPage = <EditPalettePage selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setSelectedPalette={setSelectedPalette} setPage={page => setPage(page)}/>
    } else if (page === 'CompletePage') {
      renderedPage = <CompletePage finishedGenerating={finishedGenerating} setFinishedGenerating={setFinishedGenerating} selectedBrandName={selectedBrandName} selectedFont={selectedFont} selectedPalette={selectedPalette} setPage={page => setPage(page)}/>
    }
  }

  return (<Layout>
    {!isXsDown && <CloseButton/>}
    <SEO title='Create Brand'/> {renderedPage}
  </Layout>)
}

export default IndexPage
