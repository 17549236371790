import React, {useState} from "react"
import Combobox from "../WidgetComponents/Combobox"
import NavButtons from "../WidgetComponents/NavButtons"
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import WebFont from 'webfontloader';

let allGoogleFonts

function SearchFontPage(props) {
  const [font, setFont] = useState(null)
  const [loaded, setLoaded] = useState(false)

  if (!loaded) {
    if (!allGoogleFonts) {
      fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyADD6xPioonVhG9x-tNE0u7-2RvuV3-Jws')
        .then(function(response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response
            .json()
            .then(function(data) {
              allGoogleFonts = data
                .items
                .map(font => font.family)
              if (typeof window !== `undefined`) {
                // WebFont.load({
                //   google: {
                //     families: allGoogleFonts.map(font => font.family + ':lighter,normal,bold,bolder')
                //   }
                // });
              }
              // console.log(allGoogleFonts.map(font => font.family))
              setLoaded(true)
            });
        })
        .catch(function(err) {
          console.log('Fetch Error :-S', err);
        });
    } else {
      setLoaded(true)
    }
  }

  // useEffect(() => {
  //    if (!firebase)
  //      return;
  //   console.log(font)
  //   console.log('Calling WebFont')
  //   WebFont.load({
  //     google: {
  //       families: font + ':lighter,normal,bold,bolder'
  //     }
  //   });
  //
  // });

  function setCurrentFont(font) {
    setFont(font)
    // console.log(font)
  }

  function goToNextPage(page) {
    props.setSelectedFont(font)
    props.setPage(page)
  }

  return (<div>
    {
      loaded && <Grow in="in">
          <Grid container="container" direction="column" justify="center" alignItems="center" style={{
              backgroundColor: '',
              margin: 'auto',
              height: '100vh'
            }}>
            <Grid container="container" direction="column" justify="center" alignItems="center" style={{
                backgroundColor: '',
                margin: 'auto',
                paddingBottom: '50px'
                // height: '100vh'
              }}>
              <h1 style={{
                  fontFamily: 'Work Sans',
                  fontWeight: '400',
                  fontSize: '30px',
                  padding: '12px'
                }}>Pick Your Font</h1>
              <Combobox allFonts={allGoogleFonts} setFont={font => setCurrentFont(font)}/>
              <NavButtons backPage='IntroFontPage' nextPage='IntroColorPage' setPage={page => goToNextPage(page)} selectionMade={allGoogleFonts.includes(font)}/>
            </Grid>
          </Grid>
        </Grow>
    }
  </div>)
}

export default SearchFontPage

// <h1 style={{
//     fontFamily: font,
//     fontSize: '60px'
//   }}>{props.selectedBrandName} in {font}</h1>
