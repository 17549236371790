import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {}
  },
  selectedCard: {
    backgroundColor: '#deffeb',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    }
  }
}));

function FontList(props) {
  const classes = useStyles();

  let fontList = props
    .fonts ? props
    .fonts
    .map(font => <Card className={font === props.activeFont
        ? classes.selectedCard
        : classes.card} variant='outlined' elevation='0' onClick={event => props.setFont(font)}>
      <Typography style={{
          fontFamily: font,
          fontWeight: 'bold',
          fontSize: '30px',
          margin: '0'
        }}>{font}</Typography>
    </Card>) : null

  return fontList
}

export default FontList
