import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {navigate} from "gatsby"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {useTheme} from '@material-ui/core/styles';

// import Layout from "../components/layout";

const useStyles = makeStyles(theme => ({
  closeIcon: {
    color: '#e8e8e8',
    margin: '40px 50px',
    '&:hover': {
      color: '#d6d6d6',
      cursor: 'pointer'
    }
  }
}));

export default function CloseButton(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleScrap() {
    window
      .analytics
      .track("Abandoned Brand Creation");
    navigate('/my/dashboard')
  }

  const classes = useStyles();

  let warningDialog = (<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        If you leave creator mode now, you'll lose what you've done so far.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='' style={{
          color: 'grey'
        }}>
        Not Yet
      </Button>
      <Button onClick={handleScrap} variant='' style={{
          color: 'black'
        }}>
        Scrap My Brand
      </Button>
    </DialogActions>
  </Dialog>)

  return (<div>
    {warningDialog}
    <Grid container="container" direction="row" justify="flex-end" alignItems="center" style={{
        // padding: '30px'
        width: '100%',
        position: 'fixed'
      }}>
      <HighlightOffIcon className={classes.closeIcon} onClick={handleClickOpen} fontSize="large"/>
    </Grid>
  </div>);
}
