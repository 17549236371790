import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';

let fontsToLoad = [
  'Work Sans',
  'Lobster',
  // 'Dancing Script',
  // 'Raleway',
  // 'Poppins',
  // 'Playfair Display',
  // 'Bebas Neue',
  // 'Bangers',
  // 'Comfortaa',
  // 'Abril Fatface',
  // 'Righteous',
  // 'Fredoka One',
  // 'Pacifico',
  // 'Courgette'
]

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: fontsToLoad.map(font => font + ':lighter,normal,bold,bolder')
    }
  });
}

function IntroFontPage(props) {

  return (<Grow in="in">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        height: '100vh',
        width: '100vw',
        padding: '0px 5vw'
      }}>
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          // height: '100vh',
          // width: '100vw',
          // padding: '0px 5vw'
        }}>
      <Grid container="container" direction="row" justify="center" alignItems="center">
        <Grid item="item">
          <Grid container="container" direction="column" justify="center" alignItems="center" style={{
              // height: '25vh',
              backgroundColor: '',
              padding: '20px 20px 60px',
              width: '300px'
            }}>
            <Typography style={{
                fontFamily: 'Work Sans',
                fontWeight: '200',
                fontSize: '18px',
                color: 'grey',
                lineHeight: '1.1',
                textAlign: 'center',
                paddingBottom: '10px'
                // textAlign: 'center'
              }}>My Brand</Typography>
            <Typography style={{
                fontFamily: 'Work Sans',
                fontWeight: 'bold',
                fontSize: '40px',
                lineHeight: '1.1',
                textAlign: 'center'
              }}>{props.selectedBrandName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container="container" direction="column" justify="flex-start" alignItems="center" style={{
          // height: '50vh',
          backgroundColor: ''
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontSize: '55px',
            fontWeight: 'bolder',
            textAlign: 'center',
            lineHeight: 1.1,
            // height: '40px',
            paddingBottom: '20px'
          }}>Now let's find your font.</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '30px',
            paddingBottom: '30px',
            textAlign: 'center',
            lineHeight: 1.2
          }}>Do you want to choose a new font or do you have one in mind?</Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center">
          <Button onClick={event => props.setPage('SelectFontTypePage')} variant='contained' color='secondary' size='large' style={{
              margin: '0px 10px'
            }}>Choose a new font</Button>
          <Button onClick={event => props.setPage('SearchFontPage')} variant='outlined' color='secondary' size='large' style={{
              margin: '0px 10px'
            }}>I know my font</Button>
        </Grid>
        <Button color='' onClick={event => props.setPage('EnterNamePage')} size='large' style={{
            margin: '60px',
            color: 'grey'
          }}>Back</Button>
      </Grid>
      </Grid>
    </Grid>
  </Grow>)
}

export default IntroFontPage

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
