import React from "react"
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

function NavButtons(props) {

  return (<Grid style={{
      margin: '20px'
    }}>
    <Button onClick={event => props.setPage(props.backPage)} size='large' style={{
        margin: '10px',
        color: 'grey'
      }}>Back</Button>
    <Button onClick={event => props.setPage(props.nextPage)} disabled={!props.selectionMade} variant="contained" color="secondary" size='large' style={{
        margin: '10px'
      }}>Continue</Button>
  </Grid>)
}

export default NavButtons
