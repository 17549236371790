import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {}
  },
  selectedCard: {
    backgroundColor: '#deffeb',
    margin: '1vw',
    padding: '15px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    }
  }
}));

function arraysAreEqual(array1, array2) {
  if ((array1.length === array2.length) && array1.every(function(element, index) {
    return element === array2[index];
  })) {
    return true
  }
}

function ColorList(props) {
  const classes = useStyles();

  let ColorList = props
    .palettes
    .map(palette => <Card className={arraysAreEqual(palette, props.activePalette)
        ? classes.selectedCard
        : classes.card} variant='outlined' elevation='0' onClick={event => props.setPalette(palette)}>
      <Grid container="container" direction="row" justify="space-evenly" alignItems="center">
        {
          palette.map(paletteColor => <div style={{
              backgroundColor: paletteColor,
              width: '30px',
              height: '30px',
              borderRadius: '100px',
              margin: '3px',
              border: paletteColor === '#ffffff' ? '0.5px solid #ebebeb' : ''
            }}></div>)
        }
      </Grid>
    </Card>)

  return ColorList
}

export default ColorList
