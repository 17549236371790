import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox(props) {
  // const handleChange = (event) => {
  //   console.log('ComboBox: ' + event.target.textContent)
  //   props.setFont(event.target.textContent)
  // };

  return (<Autocomplete id="combo-box-demo" options={props.allFonts} getOptionLabel={(option) => option} style={{
      width: 300
    }} renderInput={(params) => <TextField {...params} label="Search Google Fonts" variant="outlined"/>} onInputChange={(event, newInputValue) => {
          props.setFont(newInputValue);
        }}/>);
}
